// import React, { useEffect, useState } from "react";
// import {
//   AppBar,
//   Toolbar,
//   Container,
//   BottomNavigation,
//   BottomNavigationAction,
//   useMediaQuery,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogContentText,
//   DialogActions,
//   Button,
// } from "@mui/material";
// import HomeIcon from "@mui/icons-material/Home";
// import AssistantIcon from "@mui/icons-material/Assistant";
// import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
// import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import { useTheme } from "@emotion/react";

// function UnibicNav({ isInputFocused, isAuthenticated, handleLogout }) {
//   const theme = useTheme();
//   const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));
//   const navigate = useNavigate();
//   const location = useLocation();

//   const shouldShowTitleImage = location.pathname !== "/home" && isAuthenticated;
//   const getSelectedValue = () => {
//     switch (location.pathname) {
//       case "/home":
//         return 0;
//       case "/chat":
//         return 1;
//       case "/screens":
//         return 2;
//       case isAuthenticated ? "/Logout" : "/login":
//         return 3;
//       default:
//         return -1;
//     }
//   };
//   const [value, setValue] = useState(getSelectedValue());
//   const [openLogoutDialog, setOpenLogoutDialog] = useState(false);

//   useEffect(() => {
//     setValue(getSelectedValue());
//   }, [location]);

//   const handleLogoutConfirm = () => {
//     const user = sessionStorage.getItem("userDetails");
//     if (!user) {
//       setOpenLogoutDialog(false);
//       navigate("/login");
//       return;
//     }
//     setOpenLogoutDialog(false);
//     handleLogout(user?.username);
//     navigate("/login");
//   };

//   const handleLogoutClick = () => {
//     setOpenLogoutDialog(true);
//   };

//   const handleDialogClose = () => {
//     setOpenLogoutDialog(false);
//     navigate("/home");
//   };

//   return (
//     <>
//       {isMediumDown ? (
//         <BottomNavigation
//           showLabels
//           value={value}
//           onChange={(event, newValue) => {
//             setValue(newValue);
//             if (newValue === 0) navigate("/home");
//             if (newValue === 1) navigate("/chat");
//             if (newValue === 2) navigate("/screens");
//             if (newValue === 3)
//               isAuthenticated ? handleLogoutClick() : navigate("/login");
//           }}
//           sx={{
//             display: isInputFocused ? "none" : "",
//             position: isMediumDown ? "sticky" : "fixed",
//             bottom: 0,
//             left: 0,
//             right: 0,
//             background: "inherit",
//             zIndex: theme.zIndex.drawer + 1,
//             "& .Mui-selected": {
//               color: "#d52c2c",
//             },
//             "& .MuiBottomNavigationAction-root.Mui-selected": {
//               "& .MuiBottomNavigationAction-label": {
//                 color: "#d52c2c",
//               },
//               "& .MuiSvgIcon-root": {
//                 color: "#d52c2c",
//               },
//             },
//           }}
//         >
//           <BottomNavigationAction label="Home" icon={<HomeIcon />} />
//           <BottomNavigationAction label="Chat" icon={<AssistantIcon />} />
//           <BottomNavigationAction
//             label="Screens"
//             icon={<TipsAndUpdatesIcon />}
//           />
//           <BottomNavigationAction
//             label={isAuthenticated ? "Logout" : "Login"}
//             icon={<AccountCircleIcon />}
//           />
//         </BottomNavigation>
//       ) : (
//         <AppBar
//           position="fixed"
//           sx={{
//             height: "10%",
//             boxShadow: "none",
//             zIndex: theme.zIndex.drawer + 1,
//             background: "inherit",
//           }}
//         >
//           <Toolbar
//             disableGutters
//             sx={{
//               display: "flex",
//               justifyContent: "space-between",
//               alignItems: "center",
//               flexDirection: "row",
//               height: "100%",
//               gap: "2%",
//               maxWidth: "100%",
//               padding: "0 2%",
//             }}
//           >
//             <div
//               style={{
//                 display: "flex",
//                 gap: "3%",
//                 width: "100%",
//                 alignItems: "center",
//               }}
//             >
//               <Link
//                 to="/home"
//                 style={{
//                   textDecoration: "none",
//                   color: "hsl(207, 12%, 43%)",
//                   fontSize: "16px",
//                   fontWeight: "bolder",
//                 }}
//               >
//                 HOME
//               </Link>
//               <Link
//                 to="/chat"
//                 style={{
//                   textDecoration: "none",
//                   color: "hsl(207, 12%, 43%)",
//                   fontSize: "16px",
//                   fontWeight: "bold",
//                 }}
//               >
//                 CHAT
//               </Link>
//               <Link
//                 to="/screens"
//                 style={{
//                   textDecoration: "none",
//                   color: "hsl(207, 12%, 43%)",
//                   fontSize: "16px",
//                   fontWeight: "bold",
//                 }}
//               >
//                 SCREENS
//               </Link>
//               <div
//                 style={{
//                   display: "flex",
//                   width: "100%",
//                   justifyContent: "space-between",
//                   marginLeft: "30%",
//                   alignItems:"center"
//                 }}
//               >
//                 {/* Mobile logo display (Jockey)  */}
//                 <img
//                   fetchpriority="high"
//                   width={"140"}
//                   height={"64"}
//                   src="https://1000logos.net/wp-content/uploads/2020/07/Jockey-Logo.png"
//                   class="attachment-full size-full wp-image-2014"
//                   alt=""
//                   srcset="https://1000logos.net/wp-content/uploads/2020/07/Jockey-Logo.png 500w, https://www.unibicfoods.com/wp-content/uploads/2022/12/logo_Red-1-300x133.png 300w"
//                   sizes="(max-width: 500px) 100vw, 500px"
//                 />

//                 <div
//                   style={{
//                     fontSize: "16px",
//                     fontWeight: "500",
//                     fontFamily: "Roboto",
//                     color: "#555555",
//                   }}
//                 >
//                   AI Agent by channelco.ai
//                 </div>
//               </div>
//             </div>
//             <Button
//               onClick={() => {
//                 isAuthenticated ? handleLogoutClick() : navigate("/login");
//               }}
//               sx={{
//                 color: "hsl(207, 12%, 43%)",
//                 fontSize: "16px",
//                 fontWeight: "bold",
//               }}
//             >
//               {isAuthenticated ? "LOGOUT" : "LOGIN"}
//             </Button>
//           </Toolbar>
//         </AppBar>
//       )}
//       <Dialog
//         open={openLogoutDialog}
//         onClose={handleDialogClose}
//         aria-labelledby="logout-dialog-title"
//         aria-describedby="logout-dialog-description"
//       >
//         <DialogTitle id="logout-dialog-title">Logout Confirmation</DialogTitle>
//         <DialogContent>
//           <DialogContentText id="logout-dialog-description">
//             Are you sure you want to logout?
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button
//             sx={{ backgroundColor: "", color: "red" }}
//             onClick={handleDialogClose}
//           >
//             Cancel
//           </Button>
//           <Button
//             sx={{ backgroundColor: "", color: "red" }}
//             onClick={handleLogoutConfirm}
//           >
//             Logout
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </>
//   );
// }

// export default UnibicNav;

import React, { useState, useEffect } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import BottomNav from "../../molecules/BottomNav";
import DesktopNav from "../../molecules/DesktopNav";
import LogoutDialog from "../../molecules/LogoutDialog";

function UnibicNav({ isAuthenticated, handleLogout }) {
  const theme = useTheme();
  const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const location = useLocation();

  const [value, setValue] = useState(0);
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);

  useEffect(() => {
    const pathToIndex = {
      // "/home": 0,
      // "/chat": 1,
      // "/screens": 2,
      // "/login": 1,
      "/logout": 0,
    };

    setValue(pathToIndex[location.pathname] ?? -1);
  }, [location]);

  const handleLogoutClick = () => setOpenLogoutDialog(true);

  const handleLogoutConfirm = () => {
    handleLogout();
    setOpenLogoutDialog(false);
    navigate("/login");
  };

  return (
    <>
      {isMediumDown ? (
       isAuthenticated && (
          <BottomNav
            value={value}
            onChange={(e, newValue) => {
              setValue(newValue);

              if (newValue === 0)
                isAuthenticated ? handleLogoutClick() : navigate("/login");
            }}
            isAuthenticated={isAuthenticated}
          />
        )
      ) : (
        <DesktopNav
          isAuthenticated={isAuthenticated}
          onLogoClick={() => navigate("/chat")}
          onButtonClick={() =>
            isAuthenticated ? handleLogoutClick() : navigate("/login")
          }
        />
      )}
      <LogoutDialog
        open={openLogoutDialog}
        onCancel={() => setOpenLogoutDialog(false)}
        onConfirm={handleLogoutConfirm}
      />
    </>
  );
}

export default UnibicNav;

import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import { IconButton, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";

const options = [
  { key: "English", value: "en-IN", name: "English" },
  { key: "हिंदी", value: "hi-IN", name: "Hindi" },
  { key: "বাংলা", value: "bn-IN", name: "Bengali" },
  { key: "ಕನ್ನಡ", value: "kn-IN", name: "Kannada" },
  { key: "മലയാളം", value: "ml-IN", name: "Malayalam" },
  { key: "मराठी", value: "mr-IN", name: "Marathi" },
  { key: "ଓଡ଼ିଆ", value: "od-IN", name: "Odiya" },
  { key: "ਪੰਜਾਬੀ", value: "pa-IN", name: "Punjabi" },
  { key: "தமிழ்", value: "ta-IN", name: "Tamil" },
  { key: "తెలుగు", value: "te-IN", name: "Telugu" },
  { key: "ગુજરાતી", value: "gu-IN", name: "Gujarati" },
];

function LanguageDialog(props) {
  const { onClose, value: valueProp, open, ...other } = props;
  const [value, setValue] = useState(valueProp);
  const radioGroupRef = useRef(null);

  useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(value);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          width: "80%",
          maxHeight: 435,
          backgroundColor: "#ffffff",
          borderRadius: "12px",
        },
      }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <DialogTitle>Select Language</DialogTitle>
      <DialogContent dividers style={{ scrollbarWidth: "thin" }}>
        <RadioGroup
          ref={radioGroupRef}
          aria-label="language"
          name="language"
          value={value}
          onChange={handleChange}
          sx={{
            "& .css-1qeilas-MuiButtonBase-root-MuiRadio-root": {
              color: "rgb(249 179 186)",
            },
            "& .css-1qeilas-MuiButtonBase-root-MuiRadio-root.Mui-checked": {
              color: "rgb(249 179 186)",
            },
          }}
        >
          {options.map((option) => (
            <FormControlLabel
              value={option.value}
              key={option.key}
              control={<Radio />}
              label={option.key}
            />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button
          style={{ color: "#212121", backgroundColor: "rgb(249 179 186)" }}
          autoFocus
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          style={{ color: "#212121", backgroundColor: "rgb(249 179 186)" }}
          onClick={handleOk}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

LanguageDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
};

export default function VoiceInput({
  onTranscript,
  setIsRecording,
  isRecording,
  mediaRecorder,
  setMediaRecorder,
  isDeleted,
  setIsDeleted,
}) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  const audioChunks = useRef([]);
  const timeoutRef = useRef(null);
  const [stream, setStream] = useState(null);
  const theme = useTheme();

  const isSmallDown = useMediaQuery(theme.breakpoints.down("sm"));
  const access_token = sessionStorage.getItem("access_token");

  useEffect(() => {
    if (isDeleted && isRecording) {
      stopRecording();
      setIsDeleted(false);
    }
  }, [isDeleted, isRecording]);

  const startRecording = async (selectedLanguage) => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      const recorder = new MediaRecorder(stream);
      setMediaRecorder(recorder);
      setStream(stream);
      audioChunks.current = [];

      recorder.ondataavailable = (event) => {
        audioChunks.current.push(event.data);
      };
      recorder.onstop = async () => {
        const audioBlob = new Blob(audioChunks.current, { type: "audio/wav" });

        const audioBuffer = await audioBlob.arrayBuffer();
        // const audioContent = Buffer.from(audioBuffer).toString("base64");
        const audioContent = btoa(
          new Uint8Array(audioBuffer).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );
        const languageCode = selectedLanguage.value;
        const languageName = selectedLanguage.name;

        // Send audio to backend endpoint
        if (languageCode === "en-IN") {
          try {
            const response = await fetch(process.env.REACT_APP_SPEECH_TO_TEXT, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${access_token}`,
              },
              body: JSON.stringify({ audioContent }),
            });
            const data = await response.json();
            if (data && data.transcription) {
              onTranscript(data.transcription, languageName);
            } else {
              console.error("Failed to retrieve transcript");
            }
          } catch (error) {
            console.error("Backend speech-to-text error:", error);
          }
        } else {
          // Use Sarvam AI API for other languages
          const form = new FormData();
          form.append("file", audioBlob, "audio.wav");
          form.append("language_code", languageCode);
          form.append("model", "saarika:v1");

          const options = {
            method: "POST",
            headers: {
              "api-subscription-key": process.env.REACT_APP_SARVAM_AI_API_KEY,
            },
            body: form,
          };

          try {
            const response = await fetch(
              "https://api.sarvam.ai/speech-to-text",
              options
            );
            const data = await response.json();
            if (data && data.transcript) {
              onTranscript(data.transcript, languageName);
            } else {
              console.error("Failed to retrieve transcript");
            }
          } catch (error) {
            console.error("Error during Sarvam API conversion:", error);
          }
        }

        clearTimeout(timeoutRef.current);
        setIsRecording(false);
      };

      recorder.start();
      setIsRecording(true);

      // timeoutRef.current = setTimeout(() => {
      //   stopRecording();
      //   stream.getTracks().forEach((track) => track.stop());

      // }, 9000);
    } catch (err) {
      console.error("Error starting recording:", err);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      if (isDeleted) {
        mediaRecorder.ondataavailable = null;
        mediaRecorder.onstop = null;
        mediaRecorder.stop();
        setIsRecording(false);
        stream.getTracks().forEach((track) => track.stop());
        return;
      }

      mediaRecorder.stop();

      setIsRecording(false);
      stream.getTracks().forEach((track) => track.stop());
    } else {
      console.log("No mediaRecorder available.");
    }
  };

  const handleToggleRecording = (selectedLanguage) => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording(selectedLanguage);
    }
  };

  const handleClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      const selectedLanguage = options.find(
        (option) => option.value === newValue
      );
      setValue(selectedLanguage);
      handleToggleRecording(selectedLanguage);
    }
  };
  return (
    <>
      <IconButton
        style={{
          backgroundColor: "rgb(249 179 186)",
          marginRight: isSmallDown ? "3px" : "10px",
          padding: isSmallDown ? "5px" : isRecording ? "3px" : "8px",
        }}
        onClick={() => {
          if (value.length === 0) {
            setOpen(true);
          } else {
            handleToggleRecording(value);
          }
        }}
      >
        {isRecording ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={isSmallDown ? "20" : "24"}
            height={isSmallDown ? "20" : "24"}
            fill="currentColor"
          >
            <path fill="#2f2f2f" d="M6 6h12v12H6z"></path>
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={isSmallDown ? "20" : "24"}
            height={isSmallDown ? "20" : "24"}
            fill="currentColor"
          >
            <path
              fill="#2f2f2f"
              d="M12 15c1.66 0 2.99-1.34 2.99-3L15 6c0-1.66-1.34-3-3-3S9 4.34 9 6v6c0 1.66 1.34 3 3 3m5.3-3c0 3-2.54 5.1-5.3 5.1S6.7 15 6.7 12H5c0 3.42 2.72 6.23 6 6.72V22h2v-3.28c3.28-.48 6-3.3 6-6.72z"
            ></path>
          </svg>
        )}
      </IconButton>
      <LanguageDialog
        id="language-menu"
        keepMounted
        open={open}
        onClose={(value) => handleClose(value)}
        value={value?.value || ""}
      />
    </>
  );
}

export const settingOptions = [
  {
    title: "AGENTS",
    items: [
      {
        label: "Manage sales and customer interactions",
        shortLabel: "Sales Agent",
        mobileLabel: "Sales Agent",
        path: "sales-agent",
        icon: "🛒",
      },
      {
        label: "Handle dealer communications and engagement",
        shortLabel: "Dealer Engagement",
        mobileLabel: "Dealer Engagement",
        path: "dealer-engagement-agent",
        icon: "🤝",
      },
      {
        label: "Generate and analyze business reports",
        shortLabel: "Reporting Agent",
        mobileLabel: "Reporting Agent",
        path: "reporting-agent",
        icon: "📊",
      },
      {
        label: "Reconcile transactions and payments",
        shortLabel: "Payment Reconciliation",
        mobileLabel: "Payment Reconciliation",
        path: "payment-reconciliation-agent",
        icon: "💳",
      },
      {
        label: "Manage user roles and permissions",
        shortLabel: "User Permissions",
        mobileLabel: "User Permissions",
        path: "manage-user-roles",
        icon: "🔐",
      },
    ],
  },
];

import { useEffect, useState } from "react";
import { Box, Container, useMediaQuery } from "@mui/material";
import MessageContainer from "../components/container/messageContainer";
import MessageInput from "../components/input/messageInput";
import { v4 as uuidv4 } from "uuid";
import CategorySidebar from "../components/SalesSidebar";
import { useTheme } from "@emotion/react";
import { useLocation, useNavigate } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Header from "../components/header/Header";
import Suggestions from "../components/suggestions/Suggestions";
const Chat = ({
  categories,
  handleItemClick,
  isLoading,
  userLang,
  setUserLang,
  setIsLoading,
  setIsInputFocused,
}) => {
  const userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
  const navigate = useNavigate();

  const [feedback, setFeedback] = useState(0);
  const [messages, setMessages] = useState(() => {
    const savedMessages = sessionStorage.getItem("chatMessages");
    return savedMessages ? JSON.parse(savedMessages) : [];
  });
  const [sessionId, setSessionId] = useState(() => {
    return sessionStorage.getItem("chatSessionId") || "";
  });
  useEffect(() => {
    sessionStorage.setItem("chatMessages", JSON.stringify(messages));
    sessionStorage.setItem("chatSessionId", sessionId);
  }, [messages, sessionId]);
  const theme = useTheme();
  const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));
  const isLandscape = useMediaQuery("(orientation: landscape)");

  const location = useLocation();
  const [initialMessage, setInitialMessage] = useState("");
  const [homeVoiceLang, setHomeVoiceLang] = useState("");
  useEffect(() => {
    if (location.state?.initialMessage) {
      setInitialMessage(location.state.initialMessage);
      setHomeVoiceLang(location.state.voiceLang);
      navigate(location.pathname, { replace: true });
    }
  }, [location.state?.initialMessage]);
  const translateText = async (text, sourceLang, targetLang) => {
    try {
      const translateApiUrl = process.env.REACT_APP_TRANSLATE_API;
      const access_token = sessionStorage.getItem("access_token");
      console.log(
        `Translating text from ${sourceLang} to ${targetLang} via ${translateApiUrl}`
      );

      const response = await fetch(translateApiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
        body: JSON.stringify({
          text: text,
          sourceLang: sourceLang,
          targetLang: targetLang,
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Failed to translate text:", errorText);
        throw new Error("Failed to translate text");
      }

      const data = await response.json();
      return data.translatedText;
    } catch (error) {
      console.error("Error translating text:", error);
      throw error;
    }
  };
  const handleSendMessage = async (message, voiceLang) => {
    let senderMessage = {
      sender: "user",
      senderID: "test1",
      senderType: "admin",
      text: message,
    };
    setMessages((prevMessages) => [...prevMessages, senderMessage]);
    setIsLoading(true);
    try {
      // Step 1: Translate the user's message to English
      let translatedQuery = message;
      if (voiceLang && voiceLang !== "English") {
        translatedQuery = await translateText(message, voiceLang, "English");
      }

      const combinedMessage = `${translatedQuery}`;
      const apiUrl = process.env.REACT_APP_CHAT_API;
      const access_token = sessionStorage.getItem("access_token");
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
        body: JSON.stringify({
          query: combinedMessage,
          phoneNumber: "919381264039",
        }),
      });

      setIsLoading(false);
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      const chatResponse = data;
      const userPrompt = "";
      const salesData = [];

      senderMessage = {
        ...senderMessage,
        userPrompt: userPrompt,
      };
      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages];
        updatedMessages[updatedMessages?.length - 1] = senderMessage;
        return updatedMessages;
      });
      let translatedResponse;

      if (salesData?.length === 0) {
        const formattedResponse = chatResponse.startsWith('"')
          ? JSON.parse(chatResponse)
          : chatResponse;

        translatedResponse = formattedResponse;
        if (userLang !== "English") {
          translatedResponse = await translateText(
            formattedResponse,
            "English",
            userLang
          );
          console.log(
            " translating response to userLanguage",
            translatedResponse
          );
        }
      }

      const botMessage = {
        sender: "bot",
        id: uuidv4(),
        text: salesData?.length !== 0 ? "" : translatedResponse,
        productDetails: data.productDetails,
        sqlData: salesData,
      };

      setMessages((prevMessages) => [...prevMessages, botMessage]);

      const { sqlData, ...botMessageForSave } = botMessage;
      // await saveChatSession(senderMessage, botMessageForSave, feedback);
    } catch (error) {
      console.error("Error handling message:", error);
      setIsLoading(false);
    }
  };

  const saveChatSession = async (senderMessage, botMessage, feedback) => {
    try {
      const apiUrlSaveSession = process.env.REACT_APP_SAVE_CHAT_API;
      const access_token = sessionStorage.getItem("access_token");
      const response = await fetch(apiUrlSaveSession, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
        body: JSON.stringify({
          id: sessionId,
          userMessage: senderMessage,
          botResponse: botMessage,
          responseFeedback: feedback,
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Error saving chat session:", errorText);
        throw new Error("Error saving chat session");
      }

      const data = await response.json();
      setSessionId(data.id);
    } catch (error) {
      console.error("Error saving chat session:", error);
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "bot", text: "Error generating response" },
      ]);
    }
  };

  useEffect(() => {
    if (initialMessage) {
      handleSendMessage(initialMessage, homeVoiceLang);
      setInitialMessage("");
    }
  }, [initialMessage, messages]);
  const [vh, setVh] = useState(window.innerHeight - 85);

  useEffect(() => {
    const handleResize = () => {
      setVh(window.innerHeight - 85);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Container
      maxWidth="lg"
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: isMediumDown ? "0px 8px 56px" : "",
        flexGrow: isMediumDown && 1,
        minHeight: isMediumDown ? "none" : "85vh",
        overflowY: isMediumDown ? "auto" : "auto",
        marginTop: isMediumDown ? "" : "77px",
        maxHeight: isMediumDown ? `${vh}px ` : "90vh",
        scrollbarWidth: "none",
      }}
    >
      {isMediumDown && (
        <Header
          isMediumDown={isMediumDown}
          page={"chat"}
          handleSend={handleSendMessage}
        />
      )}
      {/* {!isMediumDown && (
        <>
          <CategorySidebar
            categories={categories}
            handleItemClick={handleItemClick}
          />
          <Suggestions handleSend={handleSendMessage} />
        </>
      )} */}

      <Box
        sx={{
          flexGrow: 1,
          // marginLeft: isMediumDown
          //   ? "0px"
          //   : messages?.length !== 0
          //   ? "220px"
          //   : "0",
          display: "flex",
          flexDirection: "column",
          marginTop: isMediumDown ? (isLandscape ? "15%" : "") : "0%",
        }}
      >
        <MessageContainer
          messages={messages}
          sessionId={sessionId}
          feedback={feedback}
          onFeedbackChange={setFeedback}
          handleSend={handleSendMessage}
          loading={isLoading}
          categories={categories}
          handleItemClick={handleItemClick}
        />

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "sticky",
            bottom: "0",
            padding: isMediumDown ? "0" : 2,
            backdropFilter: isMediumDown ? "blur(2px)" : "",
          }}
        >
          <MessageInput
            handleSend={handleSendMessage}
            userLang={userLang}
            setUserLang={setUserLang}
            isLoading={isLoading}
            setIsInputFocused={setIsInputFocused}
          />
        </Box>
      </Box>
    </Container>
  );
};

export default Chat;

export const templatesData = [
  // Dealers/Distributors
  // {
  //   id: 1,
  //   text: "📈 Boost Your Margins! Earn 15% extra profit on bulk orders of [Premium Product Line] this quarter.",
  // },
  // {
  //   id: 2,
  //   text: "🏅 Exclusive Territory Rights! Become our flagship partner in [City/Region] & get marketing collateral + training support.",
  // },
  // {
  //   id: 3,
  //   text: "📦 Stock Up & Save! Avail 20% discount on primer inventory for orders above ₹5 lakhs.",
  // },
  // {
  //   id: 4,
  //   text: "🆕 New Product Launch Support! Free demo kits & staff training for first 50 dealers to sign up.",
  // },
//   {
//     id: 5,
//     text: "🎁 Loyalty Rewards Program! Earn points for every order to redeem freebies or vacation vouchers.",
//   },

//   // Builders/Real Estate Developers
//   {
//     id: 6,
//     text: "🏗️ Budget-Friendly Solutions! Special rates on industrial-grade exterior paints for projects over 10,000 sq. ft.",
//   },
//   {
//     id: 7,
//     text: "☔ Weatherproof Guarantee! 7-year warranty on our MonsoonShield Exterior Range – perfect for your high-rise projects.",
//   },
//   {
//     id: 8,
//     text: "🚀 Speed Up Construction! Buy our 2-in-1 primer+paint combo – cuts painting time by 30%!",
//   },
//   {
//     id: 9,
//     text: "💰 Referral Bonus! Get 5% cashback for every contractor you refer to us.",
//   },
//   {
//     id: 10,
//     text: "🎨 Custom Color Matching! Free Pantone matching service for luxury apartment projects.",
//   },

//   // Contractors/Painters
//   {
//     id: 11,
//     text: "🛠️ Work Smarter! Buy 10 cans of QuickDry Pro and get 2 free spray guns!",
//   },
//   {
//     id: 12,
//     text: "🖌️ Contractor Special! 25% off on wood polish + free sanding kits for orders before [date].",
//   },
//   {
//     id: 13,
//     text: "✅ Zero Complaints Guarantee! Free touch-up paint if your clients report peeling within 2 years.",
//   },
//   {
//     id: 14,
//     text: "📚 Skill Upgrade! Attend our monthly workshop on metallic finish techniques – certificate provided!",
//   },
//   {
//     id: 15,
//     text: "🏆 Loyalty Program: Earn ₹500 for every ₹50,000 spent quarterly.",
//   },

//   // Regular Homeowners
//   {
//     id: 16,
//     text: "🏠 Transform Your Home! Free color consultation + 3D visualization with every order above ₹20,000.",
//   },
//   {
//     id: 17,
//     text: "🧒 Safe for Kids & Pets! 50% off on non-toxic EcoPure Interior Paints this festive season.",
//   },
//   {
//     id: 18,
//     text: "🎨 Weekend DIY Special! Buy 2 paint cans, get free brushes, rollers & step-by-step video guide.",
//   },
//   {
//     id: 19,
//     text: "🔗 Refer & Earn! Get ₹1,000 credit for every friend who paints their home with us.",
//   },
//   {
//     id: 20,
//     text: "🌧️ Monsoon Protection! 30% discount on anti-fungal bathroom paints – prevent black spots!",
//   },

//   // Architects/Interior Designers
//   {
//     id: 21,
//     text: "✨ Impress Your Clients! Free sample kits of our metallic/textured finishes for your next project.",
//   },
//   {
//     id: 22,
//     text: "🤝 Exclusive Collaboration! Feature your design in our catalog & earn 10% commission on referred sales.",
//   },
//   {
//     id: 23,
//     text: "🌿 Sustainability Certified! Get LEED/GRIHA compliance certificates with our GreenPro Range.",
//   },
//   {
//     id: 24,
//     text: "📖 VIP Access! Private webinar on global color trends + free Pantone swatch book.",
//   },
//   {
//     id: 25,
//     text: "🎨 Custom Solutions! We'll develop bespoke colors/textures for your luxury project – min. order 500L.",
//   },

//   // Seasonal/Holiday Campaigns (All Groups)
//   {
//     id: 26,
//     text: "🌧️ Pre-Monsoon: Protect Before the Rains! 20% off on waterproof exterior coatings.",
//   },
//   {
//     id: 27,
//     text: "🪔 Diwali/Dussehra: Festive Makeover! Buy 3 cans, get free gold/silver accent paints.",
//   },
//   {
//     id: 28,
//     text: "☀️ Summer: Beat the Heat! Heat-reflective roof paints – keeps homes 5°C cooler.",
//   },

//   // Loyalty Booster Ideas
//   {
//     id: 29,
//     text: "🥇 Tiered Discounts: Silver/Gold/Platinum tiers with escalating benefits.",
//   },
//   {
//     id: 30,
//     text: "🚀 Early Access: First preview of new collections for top 20% customers.",
//   },
//   {
//     id: 31,
//     text: "🔍 Value-Adds: Free site audits, corrosion resistance reports, or cleaning kits.",
//   },

//   // Digital Engagement Tools
//   {
//     id: 32,
//     text: "📲 AR Color Visualizer App: See how paints look in your space before buying!",
//   },
//   {
//     id: 33,
//     text: "📏 Project Calculator: Estimate paint quantity needed for your room size.",
//   },
//   {
//     id: 34,
//     text: "💬 Expert Chat Support: Connect with paint chemists for technical queries.",
//   },
];
import React from 'react'

const PaymentReconAgent = () => {
  return (
    <div>
      PaymentReconAgent
    </div>
  )
}

export default PaymentReconAgent

import React, { useState } from "react";
import CategorySidebar from "../components/sidebar/SalesSidebar";
import { settingOptions } from "../constants/settingsOptions";

import UserPermissions from "../components/settings/userPermissions";

import SalesAgent from "../components/agents/salesAgent/salesAgent";
import ReportingAgent from "../components/agents/reportingAgent";
import DealerEngagementAgent from "../components/agents/dealerEngagementAgent";
import PaymentReconAgent from "../components/agents/paymentReconAgent";

// Mapping paths to components
const componentMap = {
  "sales-agent": SalesAgent,
  "dealer-engagement-agent": DealerEngagementAgent,
  "reporting-agent": ReportingAgent,
  "payment-reconciliation-agent": PaymentReconAgent,
  "manage-user-roles": UserPermissions,
};

const Admin = () => {
  // State to track the selected component
  const [selectedComponent, setSelectedComponent] = useState(null);

  // Handle click event
  const handleItemClick = (item) => {
    setSelectedComponent(item.path);
  };

  // Get the selected component
  const SelectedComponent = selectedComponent
    ? componentMap[selectedComponent]
    : componentMap["sales-agent"];

  return (
    <div
      style={{
        display: "flex",
        // minHeight: "100vh",  // Ensure full viewport height
        height: "100%", // Fallback for older browsers
        overflow: "hidden", // Prevent any unexpected scrolling
      }}
    >
      {/* Sidebar */}
      <div
        style={{
          width: "300px", // Fixed sidebar width
          minWidth: "300px", // Prevent collapsing
          overflowY: "auto", // Add scroll if content is too long
          height: "100vh", // Full height
          // borderRight: "1px solid #e0e0e0" // Visual separation
        }}
      >
        <CategorySidebar
          categories={settingOptions}
          handleItemClick={handleItemClick}
        />
      </div>

      {/* Main Content Area */}
      <div
        style={{
          flex: 1, // Take remaining space
          padding: "20px",
          overflowY: "auto", // Add scroll if content is too long
          height: "100vh", // Full height
          boxSizing: "border-box", // Include padding in height calculation
          marginTop: "90px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          scrollbarWidth: "thin",
        }}
      >
        {SelectedComponent ? (
          <SelectedComponent />
        ) : (
          <p>Select an option from the sidebar.</p>
        )}
      </div>
    </div>
  );
};

export default Admin;

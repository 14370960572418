import React, { useEffect, useState } from "react";

const TasksData = () => {
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    fetchAllTasks();
  }, []);

  const fetchAllTasks = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_GET_ALL_TASKS);
      const data = await response.json();
      setTasks(data);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };

  const handleDisableTask = async (taskId, taskStatus) => {
    try {
      await fetch(process.env.REACT_APP_DISABLE_TASK, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ jobId: taskId, isActive: taskStatus }),
      });
      fetchAllTasks();
    } catch (error) {
      console.error("Error disabling task:", error);
    }
  };

  const handleDeleteTask = async (taskId) => {
    try {
      await fetch(process.env.REACT_APP_DELETE_TASK, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ jobId: taskId }),
      });
      fetchAllTasks();
    } catch (error) {
      console.error("Error deleting task:", error);
    }
  };

  const handleDeleteAllTasks = async () => {
    try {
      await fetch(process.env.REACT_APP_DELETE_ALL_TASKS, {
        method: "DELETE",
      });
      setTasks([]);
    } catch (error) {
      console.error("Error deleting all tasks:", error);
    }
  };

  const styles = {
    container: {
      maxWidth: "90%",
      margin: "20px auto",
      fontFamily: "Arial, sans-serif",
      textAlign: "center",
    },
    table: {
      width: "100%",
      borderCollapse: "collapse",
      marginTop: "20px",
      backgroundColor: "#fff",
      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
      borderRadius: "10px",
      overflow: "hidden",
    },
    th: {
      backgroundColor: "#4CAF50",
      color: "white",
      padding: "12px",
      textAlign: "left",
    },
    td: {
      padding: "12px",
      borderBottom: "1px solid #ddd",
    },
    stripedRow: {
      backgroundColor: "#f9f9f9",
    },
    button: {
      padding: "8px 12px",
      margin: "5px",
      border: "none",
      cursor: "pointer",
      borderRadius: "5px",
      fontWeight: "bold",
    },
    disableButton: {
      backgroundColor: "#ff9800",
      color: "white",
    },
    deleteButton: {
      backgroundColor: "#f44336",
      color: "white",
    },
    deleteAllButton: {
      backgroundColor: "#d32f2f",
      color: "white",
      padding: "10px 20px",
      fontSize: "16px",
      marginTop: "20px",
    },
  };

  return (
    <div style={styles.container}>
      <h2>Tasks List</h2>
      <table style={styles.table} border="1">
        <thead>
          <tr>
            <th style={styles.th}>Job Name</th>
            <th style={styles.th}>Job Type</th>
            <th style={styles.th}>Cron Time</th>
            <th style={styles.th}>Frequency</th>
            <th style={styles.th}>Next Run</th>
            <th style={styles.th}>Selected Group</th>
            <th style={styles.th}>Message Text</th>
            <th style={styles.th}>Media URL</th>
            <th style={styles.th}>Active</th>
            <th style={styles.th}>Created At</th>
            <th style={styles.th}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {tasks.map((task, index) => (
            <tr
              key={task.jobId}
              style={index % 2 === 0 ? styles.stripedRow : {}}
            >
              <td style={styles.td}>{task.jobName}</td>
              <td style={styles.td}>{task.jobType}</td>
              <td style={styles.td}>{task.cronTime}</td>
              <td style={styles.td}>{task.frequency}</td>
              <td style={styles.td}>{task.nextRun}</td>
              <td style={styles.td}>{task.jobData.selectedGroup}</td>
              <td style={styles.td}>{task.jobData.messageText}</td>
              <td style={styles.td}>{task.jobData.uploadMediaUrl}</td>
              <td style={styles.td}>{task.isActive ? "Yes" : "No"}</td>
              <td style={styles.td}>{task.createdAt}</td>
              <td style={styles.td}>
                <button
                  style={{ ...styles.button, ...styles.disableButton }}
                  onClick={() => handleDisableTask(task.jobId, task.isActive)}
                >
                  {task.isActive ? "Disable" : "Enable"}
                </button>
                <button
                  style={{ ...styles.button, ...styles.deleteButton }}
                  onClick={() => handleDeleteTask(task.jobId)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button style={styles.deleteAllButton} onClick={handleDeleteAllTasks}>
        Delete All Tasks
      </button>
    </div>
  );
};

export default TasksData;

import React, { useState } from "react";
import MagicImage from "../../../assets/MagicImage.jpg";

const MessageComposer = ({
  templates,
  selectedGroup,
  // handleSendNow,
  handleEditClick,
  setShowUploadModal,
  setShowScheduleModal,
  isEditing,
  setIsEditing,
  onSave,
  selectedTemplate,
  setSelectedTemplate,
  sendMessageType,
}) => {
  const [inputText, setInputText] = useState("");
  const [refinedText, setRefinedText] = useState("");
  const [originalText, setOriginalText] = useState("");
  const [showTextBox, setShowTextBox] = useState(false);

  const [templateList, setTemplateList] = useState(templates);
  const [loading, setLoading] = useState(false);

  const refineMessage = async () => {
    if (!inputText.trim()) return alert("Please enter a message to refine.");

    setLoading(true);
    try {
      const response = await fetch(process.env.REACT_APP_REFINE_MESSAGE, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          text: inputText,
          selectedGroup: selectedGroup,
          sendMessageType: sendMessageType,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setRefinedText(data.refinedText);
        setSelectedTemplate(data.refinedText);
        setShowTextBox(true);
        const newTemplate = { id: Date.now(), text: data.refinedText };
        setTemplateList([...templateList, newTemplate]);
        setInputText("");
      } else {
        alert(data.error || "Error refining message.");
      }
    } catch (error) {
      alert("Failed to refine message. Please try again.");
    }
    setLoading(false);
  };

  const handleSchedule = () => {
    setShowScheduleModal(true);
  };

  const handleEdit = () => {
    setOriginalText(refinedText);
    handleEditClick(refinedText);
  };

  const handleCancelEdit = () => {
    setRefinedText(originalText);
    setIsEditing(false);
  };

  // const handleSend = () => {
  //   handleSendNow(refinedText);
  // };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      <textarea
        value={inputText}
        onChange={(e) => setInputText(e.target.value)}
        placeholder="Enter your message..."
        style={{
          // width: "100%",
          padding: "8px",
          borderRadius: "8px",
          border: "1px solid #ccc",
          fontFamily: "Roboto",
          fontSize: "14px",
          color: "#374151",
          height: "100px",
        }}
      />
      <button
        onClick={refineMessage}
        style={{
          padding: "8px 16px",
          borderRadius: "8px",
          cursor: "pointer",
          background: "rgb(250 179 185)",
          color: "#212121",
          border: "none",
          fontFamily: "Roboto",
          fontSize: "14px",
          fontWeight: 600,
        }}
      >
        {loading ? "Refining..." : "Refine Message ✨"}
      </button>
      {showTextBox && (
        <div
          style={{
            padding: "8px",
            background: "rgb(250 179 185 / 19%)",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {isEditing ? (
            <textarea
              value={refinedText}
              onChange={(e) => setRefinedText(e.target.value)}
              style={{
                // width: "100%",
                padding: "8px",
                borderRadius: "8px",
                border: "1px solid #ccc",
                fontFamily: "Roboto",
                fontSize: "14px",
                lineHeight: "20px",
                color: "#374151",
                scrollbarWidth: "thin",
                height: "100px",
              }}
            />
          ) : (
            <div
              style={{
                fontSize: "14px",
                fontFamily: "Roboto",
                lineHeight: "20px",
              }}
            >
              {refinedText}
            </div>
          )}
          <div style={{ display: "flex", gap: "8px" }}>
            {isEditing ? (
              <>
                <button
                  style={buttonStyle}
                  onClick={() => {
                    onSave(selectedTemplate.id, refinedText);
                  }}
                >
                  Save ✅
                </button>
                <button style={buttonStyle} onClick={handleCancelEdit}>
                  Cancel ❌
                </button>
              </>
            ) : (
              <button
                style={buttonStyle}
                onClick={() => {
                  handleEdit(refinedText);
                }}
              >
                Edit Text ✏️
              </button>
            )}
            <button
              style={buttonStyle}
              onClick={() => setShowUploadModal(true)}
            >
              Upload Media 📤
            </button>
            <button style={buttonStyle} onClick={handleSchedule}>
              Schedule 📅
            </button>
            {/* <button style={buttonStyle} onClick={handleSend}>
              Send Now 🚀
            </button> */}
          </div>
        </div>
      )}
    </div>
  );
};

const buttonStyle = {
  padding: "8px 16px",
  fontSize: "14px",
  fontWeight: "500",
  color: "#4B5563",
  backgroundColor: "#FFFFFF",
  border: "1px solid #D1D5DB",
  borderRadius: "8px",
  cursor: "pointer",
  transition: "all 0.3s ease-in-out",
  margin: "5px",
};

export default MessageComposer;

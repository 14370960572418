import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";

const LogoutDialog = ({ open, onCancel, onConfirm }) => (
  <Dialog open={open} onClose={onCancel}>
    <DialogTitle>Logout Confirmation</DialogTitle>
    <DialogContent>
      <DialogContentText>Are you sure you want to logout?</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel} sx={{ color: "#fea697",fontWeight:500 }}>
        Cancel
      </Button>
      <Button onClick={onConfirm} sx={{ color: "#fea697",fontWeight:500 }}>
        Logout
      </Button>
    </DialogActions>
  </Dialog>
);

export default LogoutDialog;

import React from 'react'

const UserPermissions = () => {
  return (
    <div>
      <h2>User Permissions</h2>
    </div>
  )
}

export default UserPermissions

import React, { useEffect, useState } from "react";
import MagicPaintsSymbol from "../../../assets/MagicPaintsSymbol.png";
import MagicImage from "../../../assets/MagicImage.jpg";
import { templatesData } from "../../../constants/templatesData";
import { EditTemplateModal } from "../../modals/editTemplateModal";
import MessageComposer from "./messageComposer";
import TasksData from "./tasksData";

const SalesAgent = () => {
  const [selectedGroup, setSelectedGroup] = useState("All Groups");

  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [frequency, setFrequency] = useState("everyday");
  const [dateTime, setDateTime] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const [templates, setTemplates] = useState(templatesData);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [sendMessageType, setSendMessageType] = useState(
    "initiateConversations"
  );

  const [activeTab, setActiveTab] = useState("campaign");

  const handleScheduleSubmit = async () => {
    const dateTimeFormat = new Date(dateTime);
    const minutes = dateTimeFormat.getMinutes();
    const hours = dateTimeFormat.getHours();
    const dayOfMonth = dateTimeFormat.getDate();
    const month = dateTimeFormat.getMonth() + 1; // Months are 0-based
    let dayOfWeek = "*"; // Default: Runs any day
    let cronDateTime = "";

    switch (frequency) {
      case "everyday":
        cronDateTime = `${minutes} ${hours} * * *`; // Runs daily at specific time
        break;
      case "weekly":
        dayOfWeek = dateTimeFormat.getUTCDay(); // Get weekday (0=Sunday, 6=Saturday)
        cronDateTime = `${minutes} ${hours} * * ${dayOfWeek}`;
        break;
      case "monthly":
        cronDateTime = `${minutes} ${hours} ${dayOfMonth} * *`; // Runs on the same date every month
        break;
      case "yearly":
        cronDateTime = `${minutes} ${hours} ${dayOfMonth} ${month} *`; // Runs yearly on same date
        break;
      case "once":
        cronDateTime = `${minutes} ${hours} ${dayOfMonth} ${month} *`; // Runs yearly on same date
        break;
      default:
        throw new Error("Invalid frequency");
    }

    const formData = new FormData();

    formData.append("jobName", sendMessageType);
    formData.append("jobType", frequency === "once" ? "one-time" : "recurring");
    formData.append("cronTime", cronDateTime);
    formData.append("frequency", frequency);
    formData.append("nextRun", null);

    const jobData = {
      selectedGroup: selectedGroup,
      messageText: selectedTemplate,
    };
    formData.append("jobData", JSON.stringify(jobData));
    if (uploadedFile) {
      formData.append("file", uploadedFile);
      console.log("YESS, FILE ATTACHED");
    } else {
      console.log("NO FILE ATTACHED");
    }

    try {
      const response = await fetch(process.env.REACT_APP_GET_TASKS, {
        method: "POST",
        // headers: {
        //   "Content-Type": "application/json",
        // },
        // body: JSON.stringify(jobDetails),
        body: formData, // Send as multipart/form-data
      });

      if (!response.ok) {
        throw new Error(`Failed to schedule job: ${response.statusText}`);
      }

      const result = await response.json();
      alert(`Your message has been scheduled for ${frequency} ${dateTime}`);

      setShowScheduleModal(false);
    } catch (error) {
      console.error("Error scheduling job:", error);
      alert("Failed to schedule the job. Please try again.");
    }
  };

  const handleUploadSubmit = () => {
    if (uploadedFile) {
      alert(`You have uploaded ${uploadedFile.name} for this template.`);
      setShowUploadModal(false);
    } else {
      alert("Please select a file to upload.");
    }
  };

  const groups = [
    "Individual",
    "Multiple Individuals",
    "All Groups",
    "Dealers/Distributors",
    "Builders/Real Estate Developers",
    "Contractors/Painters",
    "Regular Homeowners",
    "Architects/Interior Designers",
  ];

  const handleEditClick = (template) => {
    setSelectedTemplate(template);
    setIsEditing(true);

    // setIsModalOpen(true);
  };

  const handleSave = (id, newText) => {
    setTemplates(
      templates.map((t) => (t.id === id ? { ...t, text: newText } : t))
    );
    setIsEditing(false);
  };

  // const handleSendNow = (templateText) => {
  //   alert(`Sent to ${selectedGroup}: "${templateText}"`);
  // };

  return (
    <div
      style={{
        // minHeight: "100vh",
        // backgroundColor: "#F9FAFB",
        padding: "32px 16px",
        width: "60%",
      }}
    >
      <div
        style={{
          // maxWidth: "768px",
          margin: "10% 0 0",
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          height: "100vh",
        }}
      >
        {/* Header */}
        <div style={{ textAlign: "center", marginBottom: "16px" }}>
          <h2
            style={{
              fontSize: "24px",
              fontWeight: "bold",
              color: "#212121",
            }}
          >
            Campaign Manager
          </h2>
          <p style={{ color: "#6B7280" }}>
            Send targeted promotions to your customers
          </p>
        </div>

        {/* Navigation Tabs */}
        <div
          style={{
            display: "flex",
            gap: "16px",
            borderBottom: "2px solid #E5E7EB",
          }}
        >
          <button
            style={{
              padding: "8px 16px",
              borderBottom:
                activeTab === "campaign" ? "2px solid blue" : "none",
            }}
            onClick={() => setActiveTab("campaign")}
          >
            Campaigns
          </button>
          <button
            style={{
              padding: "8px 16px",
              borderBottom: activeTab === "tasks" ? "2px solid blue" : "none",
            }}
            onClick={() => setActiveTab("tasks")}
          >
            Tasks
          </button>
        </div>

        {/* Content Card */}
        <div
          style={{
            backgroundColor: "#FFFFFF",
            borderRadius: "12px",
            boxShadow: "0 1px 45px rgba(0, 0, 0, 0.1)",
            padding: "24px",
            transition: "all 0.3s ease-in-out",
            // width: "1000px", // TODO:to be fixed
          }}
        >
          {activeTab === "campaign" ? (
            <>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    marginBottom: "24px",
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <label
                    style={{
                      display: "block",
                      fontSize: "14px",
                      fontWeight: "600",
                      fontFamily: "Roboto",
                      color: "#374151",
                      marginBottom: "8px",
                    }}
                  >
                    Select Customer Group
                  </label>
                  <select
                    style={{
                      padding: "8px",
                      borderRadius: "8px",
                      border: "1px solid #D1D5DB",
                      outline: "none",
                      fontSize: "14px",
                      fontWeight: "600",
                      color: "#374151",
                      fontFamily: "",
                    }}
                    value={selectedGroup}
                    onChange={(e) => setSelectedGroup(e.target.value)}
                  >
                    {groups.map((group) => (
                      <option key={group} value={group}>
                        {group}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div
                style={{ display: "flex", alignItems: "center", gap: "16px" }}
              >
                <label
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  <input
                    type="radio"
                    value="initiateConversations"
                    checked={sendMessageType === "initiateConversations"}
                    onChange={() => setSendMessageType("initiateConversations")}
                  />
                  <span>Initiate Conversation</span>
                </label>
                <label
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  <input
                    type="radio"
                    value="promotionMessage"
                    checked={sendMessageType === "promotionMessage"}
                    onChange={() => setSendMessageType("promotionMessage")}
                  />
                  <span>Promotional Message</span>
                </label>
              </div>

              {/* //TODO: Add the text input box that gets input from user and refines the msg using llm model with emoji and sales style msg , the message gets displayed in the form with the three buttons that are edit upload media and schedule and send now , on scheduling or sending the card gets added to templates and goes below.  */}
              <MessageComposer
                templates={templates}
                // selectedGroup={selectedGroup}
                // handleSendNow={handleSendNow}
                handleEditClick={handleEditClick}
                setShowUploadModal={setShowUploadModal}
                setShowScheduleModal={setShowScheduleModal}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                onSave={handleSave}
                selectedTemplate={selectedTemplate}
                setSelectedTemplate={setSelectedTemplate}
                sendMessageType={sendMessageType}
              />

              {/* Templates Cards  */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                {templates.map((template) => (
                  <div
                    key={template.id}
                    style={{
                      padding: "16px",
                      border: "1px solid #E5E7EB",
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      backgroundColor: "#F3F4F6",
                      transition: "all 0.3s ease-in-out",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "4px",
                        border: "0.5px solid black",
                        borderRadius: "12px 12px 12px 0",
                        position: "relative",
                        padding: "5px",
                        backgroundColor: "white",
                      }}
                    >
                      <img
                        src={MagicImage}
                        alt="Template"
                        loading="lazy"
                        width="170"
                        height="170"
                      />
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#1F2937",
                          width: "170px",
                        }}
                      >
                        {template.text}
                      </span>
                    </div>
                    <div
                      style={{
                        fontSize: "12px",
                        color: "#2563EB",
                        backgroundColor: "#DBEAFE",
                        padding: "4px 8px",
                        borderRadius: "999px",
                        display: "inline-block",
                        width: "150px",
                      }}
                    >
                      {selectedGroup}
                    </div>
                    <div style={{ display: "flex", gap: "4px" }}>
                      {[
                        {
                          label: "Edit Text ✏️",
                          action: () => handleEditClick(template),
                        },
                        {
                          label: "Upload Media 📤",
                          action: () => setShowUploadModal(true),
                        },
                        {
                          label: "Schedule 📅",
                          action: () => setShowScheduleModal(true),
                        },
                      ].map(({ label, action }, i) => (
                        <button
                          key={i}
                          style={{
                            padding: "8px 16px",
                            fontSize: "14px",
                            fontWeight: "500",
                            color: "#4B5563",
                            backgroundColor: "#FFFFFF",
                            border: "1px solid #D1D5DB",
                            borderRadius: "8px",
                            cursor: "pointer",
                            transition: "all 0.3s ease-in-out",
                          }}
                          onMouseOver={(e) =>
                            (e.target.style.borderColor = "#9CA3AF")
                          }
                          onMouseOut={(e) =>
                            (e.target.style.borderColor = "#D1D5DB")
                          }
                          onClick={action}
                        >
                          {label}
                        </button>
                      ))}
                      {/* <button
                    onClick={() => handleSendNow(template.text)}
                    style={{
                      padding: "8px 16px",
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#FFFFFF",
                      background: "linear-gradient(to right, #2563eb, #4f46e5)",
                      borderRadius: "8px",
                      cursor: "pointer",
                      transition: "all 0.3s ease-in-out",
                      border: "none",
                      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    }}
                    onMouseOver={(e) => (e.target.style.opacity = "0.9")}
                    onMouseOut={(e) => (e.target.style.opacity = "1")}
                  >
                    Send Now 🚀
                  </button> */}
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <TasksData />
          )}
        </div>

        {/* Footer Note */}
        <p
          style={{
            textAlign: "center",
            fontSize: "14px",
            color: "#6B7280",
            marginTop: "24px",
          }}
        >
          Messages will be delivered via email and SMS. Delivery time ~2-5
          minutes.
        </p>
      </div>
      {/* Schedule Modal */}
      {showScheduleModal && (
        <div style={modalStyle}>
          <h2>Schedule Message</h2>
          <label>Frequency:</label>
          <select
            style={buttonStyle}
            value={frequency}
            onChange={(e) => setFrequency(e.target.value)}
          >
            <option style={buttonStyle} value="once">
              Once
            </option>
            <option style={buttonStyle} value="everyday">
              Everyday
            </option>
            <option style={buttonStyle} value="weekly">
              Weekly
            </option>
            <option style={buttonStyle} value="biweekly">
              Biweekly
            </option>
            <option style={buttonStyle} value="monthly">
              Monthly
            </option>
          </select>
          <label>Date & Time:</label>
          <input
            style={buttonStyle}
            type="datetime-local"
            value={dateTime}
            onChange={(e) => setDateTime(e.target.value)}
          />
          <button style={buttonStyle} onClick={handleScheduleSubmit}>
            Submit
          </button>
          <button
            style={buttonStyle}
            onClick={() => setShowScheduleModal(false)}
          >
            Close
          </button>
        </div>
      )}

      {/* Upload Media Modal */}
      {showUploadModal && (
        <div style={modalStyle}>
          <h2>Upload Media</h2>
          <input
            style={buttonStyle}
            type="file"
            accept=".png,.jpg,.gif,.pdf,.mp3,.mp4"
            onChange={(e) => setUploadedFile(e.target.files[0])}
          />
          <button style={buttonStyle} onClick={handleUploadSubmit}>
            Submit
          </button>
          <button style={buttonStyle} onClick={() => setShowUploadModal(false)}>
            Close
          </button>
        </div>
      )}

      {/* <EditTemplateModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSave={handleSave}
        template={selectedTemplate}
      /> */}
    </div>
  );
};

const buttonStyle = {
  padding: "8px 16px",
  fontSize: "14px",
  fontWeight: "500",
  color: "#4B5563",
  backgroundColor: "#FFFFFF",
  border: "1px solid #D1D5DB",
  borderRadius: "8px",
  cursor: "pointer",
  transition: "all 0.3s ease-in-out",
  margin: "5px",
};

const modalStyle = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#FFFFFF",
  padding: "16px",
  fontSize: "14px",
  fontWeight: "500",
  color: "#4B5563",
  border: "1px solid #D1D5DB",
  borderRadius: "8px",
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  cursor: "pointer",
  transition: "all 0.3s ease-in-out",
  margin: "5px",
};

// const overlayStyle = {
//   position: "fixed",
//   top: 0,
//   left: 0,
//   width: "100%",
//   height: "100%",
//   backgroundColor: "rgba(0, 0, 0, 0.3)",
//   display: "flex",
//   justifyContent: "center",
//   alignItems: "center",
// };

// const inputStyle = {
//   width: "100%",
//   height: "80px",
//   padding: "8px",
//   fontSize: "14px",
//   border: "1px solid #D1D5DB",
//   borderRadius: "8px",
// };

export default SalesAgent;

import React from "react";
import { Container, Typography, Card, CardContent, Grid } from "@mui/material";
import { styled, useMediaQuery } from "@mui/system";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link } from "react-router-dom";
import { useTheme } from "@emotion/react";
import Header from "../components/header/Header";
const TitleTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  cursor: "pointer",
  transition: "color 0.2s ease",
  fontSize: "1.10rem",
  "&:hover": {
    color: "#263238",
    fontWeight:"bolder",
    transform: "translateY(-3px)",
  },
}));

const SmallArrow = styled(ArrowForwardIosIcon)(({ theme }) => ({
  cursor: "pointer",
  transition: "transform 0.1s ease",
  paddingLeft: "4px",
  paddingTop: "4px",
  fontSize: "1rem",
}));

const ScreenSections = () => {
  const themeItems2 = [
    {
      title: "1. SKU Performance Dashboard",
      description:
        "Analyze the performance of individual SKUs with detailed insights.",
      link: "/sku-performance-dashboard",
    },
    {
      title: "2. Outlet Growth Dashboard",
      description: "Track growth trends across different outlets.",
      link: "/outlet-growth-dashboard",
    },
    {
      title: "3. Seasonal Trends Dashboard",
      description:
        "Understand sales patterns across seasons for better planning.",
      link: "/seasonal-trends-dashboard",
    },
    {
      title: "4. High-Margin Products Dashboard",
      description: "Identify and monitor products with the highest margins.",
      link: "/high-margin-products-dashboard",
    },
    {
      title: "5. Competitor Tracking Dashboard",
      description: "Stay updated with competitor performance and strategies.",
      link: "/competitor-tracking-dashboard",
    },
    {
      title: "6. Restocking Alerts Dashboard",
      description:
        "Get timely alerts for restocking inventory to avoid stockouts.",
      link: "/restocking-alerts-dashboard",
    },
    {
      title: "7. Product Assortment Dashboard",
      description: "Evaluate and optimize your product assortment effectively.",
      link: "/product-assortment-dashboard",
    },
    {
      title: "8. Sales Funnel Dashboard",
      description: "Visualize and analyze the stages of your sales funnel.",
      link: "/sales-funnel-dashboard",
    },
    {
      title: "9. Lost Sales Dashboard",
      description:
        "Identify and understand the reasons for lost sales opportunities.",
      link: "/lost-sales-dashboard",
    },
    {
      title: "10. Customer Feedback Dashboard",
      description:
        "Analyze customer feedback to improve products and services.",
      link: "/customer-feedback-dashboard",
    },
  ];
  const theme = useTheme();
  const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));
  const isLandscape = useMediaQuery("(orientation: landscape)");

  return (
    <Container
      maxWidth="lg"
      sx={{
        backgroundColor: "none",
        paddingLeft: { xs: "3%", md: "16px" },
        paddingRight: { xs: "2%", md: "16px" },
        paddingBottom: { xs: "1%", md: "20%" },
        paddingTop: { xs: "1%", md: "" },
        height: { xs: "100vh", md: "none" },
        overflowY: { xs: "auto", md: "unset" },
      }}
    >
      {isMediumDown && <Header isMediumDown={isMediumDown} page={"screens"} />}
      <Card
        sx={{
          backgroundColor: isMediumDown ? "inherit" : "#e9d4bb",
          padding: 3,
          boxShadow: 0,
          paddingTop: 5,
          borderRadius: { xs: 0, md: 5 },
          marginTop:
            isMediumDown && isLandscape ? "1%" : { xs: "20%", md: "10%" },
          paddingBottom: { xs: "56px", md: "" },
        }}
      >
        <Typography
          variant="h6"
          gutterBottom
          fontWeight="bold"
          sx={{
            paddingBottom: { xs: "2.5%", md: "1%" },
            paddingTop: { xs: "2.5%", md: "1%" },
          }}
        >
          Sales Growth & Strategy Insights{" "}
        </Typography>
        <Grid container spacing={2}>
          {themeItems2.map((item, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Link
                to={item.link}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Card
                  sx={{
                    maxWidth: { xs: "100%", md: 500 },
                    margin: "auto",
                    boxShadow: 0,
                    borderRadius: 2,
                    backgroundColor: "rgb(255 255 255 / 94%)",
                  }}
                  variant="outlined"
                >
                  <CardContent>
                    <TitleTypography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        fontSize: "0.9rem",
                      }}
                    >
                      {item.title} <SmallArrow />
                    </TitleTypography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        display: {
                          xs: "block",
                          sm: "block",
                          fontSize: "0.7rem",
                        },
                      }}
                    >
                      {item.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Card>
    </Container>
  );
};

export default ScreenSections;

import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Chat from "./pages/Chat";
import Screens from "./pages/Screens";
import UnibicNav from "./components/navbar/unibicNavbar";
import ItemPage from "./components/ItemPage";
import Home from "./pages/Home";
import SalesOverviewPage from "./components/screen/todaySales";
import TopOutletOpportunities from "./components/screen/topOutlets";
import NewOutletRadar from "./components/screen/outletRadar";
import HeroOfTheDayChallenge from "./components/screen/DayHero";
import ProductPushPriority from "./components/screen/productPushPriority";
import SalesObstacleMap from "./components/screen/salesObstacleMap";
import InstantWins from "./components/screen/instantWins";
import NextBestAction from "./components/screen/nextBestAction";
import CompetitorPulse from "./components/screen/competitorPulse";
import CustomerFeedbackScorecard from "./components/screen/customerFeedbackScoreCard";
import Login from "./pages/login";
import Admin from "./pages/Admin";

const Navigator = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isItemLoading, setIsItemLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!sessionStorage.getItem("access_token")
  );
  const [userLang, setUserLang] = useState("English");

  const [isInputFocused, setIsInputFocused] = useState(false);
  const categories = [
    {
      title: "FAQs",
      items: [
        {
          label: "What are the top-selling products in my region this month?",
          shortLabel: "Top-Selling Products",
          mobileLabel: "Top-Selling Products",
          path: "top-selling-products-region-month",
        },
        {
          label: "Which outlets show the best growth opportunities?",
          shortLabel: "Growth Opportunities",
          mobileLabel: "Growth Opportunities",
          path: "outlets-growth-opportunities",
        },
        {
          label: "Which products are running low on stock across my outlets?",
          shortLabel: "Low Stock Products",
          mobileLabel: "Low Stock Products",
          path: "products-low-stock",
        },
        {
          label: "What are the key sales trends in my region this quarter?",
          shortLabel: "Quarterly Trends",
          mobileLabel: "Quarterly Trends",
          path: "sales-trends-region-quarter",
        },
        {
          label: "Which products should I focus on to increase sales?",
          shortLabel: "Focus Products",
          mobileLabel: "Focus Products",
          path: "products-focus-sales",
        },
        {
          label: "Which outlets haven’t placed an order this month?",
          shortLabel: "Non-Ordering Outlets",
          mobileLabel: "Non-Ordering Outlets",
          path: "outlets-no-orders-month",
        },
        {
          label: "What are the top-selling categories in my region?",
          shortLabel: "Top Categories",
          mobileLabel: "Top Categories",
          path: "top-selling-categories-region",
        },
        {
          label: "Which products are trending this season?",
          shortLabel: "Seasonal Trends",
          mobileLabel: "Seasonal Trends",
          path: "trending-products-season",
        },
        {
          label: "How are new product launches performing in my region?",
          shortLabel: "New Product Performance",
          mobileLabel: "New Product Performance",
          path: "new-product-launch-performance",
        },
        {
          label: "What are the sales trends for each outlet in my territory?",
          shortLabel: "Outlet Sales Trends",
          mobileLabel: "Outlet Sales Trends",
          path: "outlet-sales-trends",
        },
        {
          label: "Which products can boost my margins this month?",
          shortLabel: "High-Margin Products",
          mobileLabel: "High-Margin Products",
          path: "products-high-margin-month",
        },
        {
          label: "Which products haven’t sold in the last 30 days?",
          shortLabel: "Non-Selling Products",
          mobileLabel: "Non-Selling Products",
          path: "products-not-sold-30-days",
        },
      ],
    },
  ];

  const handleItemClick = async (item, navigate) => {
    const access_token = sessionStorage.getItem("access_token");
    const salesDataApi = `${process.env.REACT_APP_SALES_DATA_API}/${item.path}`;
    setIsItemLoading(true);
    const response = await fetch(salesDataApi, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
    });
    if (!response.ok) {
      console.error("Failed to fetch data:", response.statusText);
      return;
    }

    setIsItemLoading(false);
    const data = await response.json();

    navigate(`/item/${item.path}`, {
      state: {
        question: item.label,
        data: data,
        categories: categories,
      },
    });
  };

  const handleLogin = async (username, password, navigate) => {
    const loginApi = process.env.REACT_APP_LOGIN_API;
    const response = await fetch(loginApi, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, password }),
    });

    const loginData = await response.json();
    const userDetails = {
      username: loginData?.username,
      name: loginData?.name,
      role: loginData?.role,
    };
    if (!response.ok) {
      console.error("Failed to login", response.statusText);
      return;
    }
    setIsAuthenticated(true);
    sessionStorage.setItem("access_token", loginData?.token);
    sessionStorage.setItem("userDetails", JSON.stringify(userDetails));

    navigate("/chat");
  };

  const handleLogout = async (username) => {
    const logoutApi = process.env.REACT_APP_LOGOUT_API;
    const access_token = sessionStorage.getItem("access_token");
    const response = await fetch(logoutApi, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
      body: JSON.stringify({ username }),
    });

    const logoutData = await response.json();
    setIsAuthenticated(false);
    sessionStorage.clear();
  };

  const PrivateRoute = ({ children }) => {
    return isAuthenticated ? children : <Navigate to="/login" />;
  };

  return (
    <Router>
      <UnibicNav
        isInputFocused={isInputFocused}
        isAuthenticated={isAuthenticated}
        handleLogout={handleLogout}
      />
      <Routes>
        <Route path="/login" element={<Login onLogin={handleLogin} />} />
        <Route path="/admin" element={<Admin onLogin={handleLogin} />} />

        {/* <Route
          path="/home"
          element={
            <PrivateRoute>
              <Home
                categories={categories}
                handleItemClick={handleItemClick}
                isLoading={isLoading}
                userLang={userLang}
                setUserLang={setUserLang}
                setIsInputFocused={setIsInputFocused}
                isItemLoading={isItemLoading}
              />
            </PrivateRoute>
          }
        /> */}
        <Route
          path="/chat"
          element={
            isAuthenticated ? (
              <Chat
                categories={categories}
                handleItemClick={handleItemClick}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                userLang={userLang}
                setUserLang={setUserLang}
                setIsInputFocused={setIsInputFocused}
              />
            ) : (
              <Login onLogin={handleLogin} />
            )
          }
        />
        {/* <Route
          path="/screens"
          element={
            <PrivateRoute>
              <Screens />
            </PrivateRoute>
          }
        />
        <Route
          path="/item/:path"
          element={
            <PrivateRoute>
              <ItemPage />
            </PrivateRoute>
          }
        /> */}
        <Route
          path="/"
          element={<Navigate to={isAuthenticated ? "/chat" : "/login"} />}
        />
        {/* <Route
          path="/sku-performance-dashboard"
          element={
            <PrivateRoute>
              <SalesOverviewPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/outlet-growth-dashboard"
          element={
            <PrivateRoute>
              <TopOutletOpportunities />
            </PrivateRoute>
          }
        />
        <Route
          path="/seasonal-trends-dashboard"
          element={
            <PrivateRoute>
              <NewOutletRadar />
            </PrivateRoute>
          }
        />
        <Route
          path="/high-margin-products-dashboard"
          element={
            <PrivateRoute>
              <HeroOfTheDayChallenge />
            </PrivateRoute>
          }
        />
        <Route
          path="/competitor-tracking-dashboard"
          element={
            <PrivateRoute>
              <ProductPushPriority />
            </PrivateRoute>
          }
        />
        <Route
          path="/restocking-alerts-dashboard"
          element={
            <PrivateRoute>
              <SalesObstacleMap />
            </PrivateRoute>
          }
        />
        <Route
          path="/product-assortment-dashboard"
          element={
            <PrivateRoute>
              <InstantWins />
            </PrivateRoute>
          }
        />
        <Route
          path="/sales-funnel-dashboard"
          element={
            <PrivateRoute>
              <NextBestAction />
            </PrivateRoute>
          }
        />
        <Route
          path="/lost-sales-dashboard"
          element={
            <PrivateRoute>
              <CompetitorPulse />
            </PrivateRoute>
          }
        />
        <Route
          path="/customer-feedback-dashboard"
          element={
            <PrivateRoute>
              <CustomerFeedbackScorecard />
            </PrivateRoute>
          }
        /> */}
      </Routes>
    </Router>
  );
};

export default Navigator;

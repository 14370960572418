import React, { useState } from "react";
import { Box, Typography, Chip, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import "swiper/css";
import "swiper/css/pagination";
import { useNavigate } from "react-router-dom";

const CategorySidebar = ({ categories, handleItemClick }) => {
  const [selectedCategory, setSelectedCategory] = useState(categories[0].items[0].path);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isLandscape = useMediaQuery("(orientation: landscape)");
  const navigate = useNavigate();

  const handleCategoryClick = (item, navigate) => {
    handleItemClick(item, navigate);
    setSelectedCategory(item.path);
  };
  return (
    <>
      {isMobile ? (
        <Box sx={{ mt: isLandscape ? "2%" : "25%", width: "100%", pb: "2px" }}>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "14px",
              fontWeight: "bold",
              lineHeight: "16px",
              marginBottom: "1%",
            }}
          >
            {categories[0]?.title}
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              overflowX: "auto",
              scrollbarWidth: "none",
              padding: "1% 2%",
            }}
          >
            {categories[0]?.items.map((item) => (
              <Chip
                key={item.path}
                label={item.mobileLabel}
                onClick={() => handleCategoryClick(item)}
                sx={{
                  flexShrink: 0,
                  fontSize: "12px",
                  height: "32px",
                  backgroundColor:
                    selectedCategory === item.path
                      ? "rgb(221, 195, 164)"
                      : "#ffffffc9",
                  border:
                    selectedCategory === item.path
                      ? "none"
                      : "1px solid #ffffffc9",
                  color: "#212121",
                  cursor: "pointer",
                  "& .MuiChip-label": {
                    paddingLeft: "8px",
                    paddingRight: "8px",
                  },
                  "&:hover": {
                    border: "none",
                    backgroundColor: "rgb(221, 195, 164)",
                    fontWeight: "500",
                  },
                }}
              />
            ))}
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            position: "fixed",
            left: 0,
            top: "80px",
            bottom: 0,
            width: "260px",
            padding: "16px 16px 0px",
            background: "inherit",
            borderRight: "1px solid #ccc",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {categories.map((category) => (
            <Box key={category.title} sx={{ marginBottom: 1 }}>
              <Typography
                sx={{
                  marginBottom: 1,
                  fontSize: "16px",
                  fontWeight: "bold",
                  paddingTop: "20px",
                }}
              >
                {category.title}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                {category.items.map((item) => (
                  <Chip
                    key={item.path}
                    label={
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "6px",
                        }}
                      >
                        {item.icon ? (
                          <span style={{ fontSize: "16px" }}>{item.icon}</span>
                        ) : (
                          <></>
                        )}
                        {item.shortLabel}
                      </span>
                    }
                    onClick={() => handleCategoryClick(item, navigate)}
                    sx={{
                      backgroundColor:
                        selectedCategory === item.path
                          ? "rgb(249 177 182 / 77%)"
                          : "inherit",
                      // border:
                      //   selectedCategory === item.path
                      //     ? "none"
                      //     : "1px solid #212121",
                      color: "#212121",
                      cursor: "pointer",
                      fontSize: "13px",
                      "&:hover": {
                        // border: "none",
                        backgroundColor: "rgb(249 177 182 / 77%)",
                        fontWeight: "500",
                      },
                    }}
                  />
                ))}
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};

export default CategorySidebar;

import React from "react";
import { useNavigate } from "react-router-dom";
import JockeyLogo from "../../assets/jockey_logo.png";
import ApparelLogo2 from "../../assets/ApparelLogo2.png";
import NoviroLogo from "../../assets/Noviro.png";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import Suggestions from "../suggestions/Suggestions";
import MagicPaintsSymbol from "../../assets/MagicPaintsSymbol.png";

const Header = ({ isMediumDown, page, handleSend }) => {
  const navigate = useNavigate();
  const isLandscape = useMediaQuery("(orientation: landscape)");
  const theme = useTheme();

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    padding: isLandscape ? "1%" : "",
    position: isLandscape && page === "table" ? "" : "fixed",
    top: 0,
    left: 0,
    height: "9%",
    // background: "rgb(221 195 164)",
    background:
      "linear-gradient(168deg, rgba(253, 79, 49, 0.5) 45%, rgba(221, 36, 118, 0.3) 70%)",
    zIndex: 1000,
  };

  const textContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  const textStyle = {
    fontSize: "16px",
    fontWeight: "500",
    color: "#555555",
  };

  // const handleLogoClick = () => {
  //   if (page === "screensItem") {
  //     navigate(-1);
  //   } else {
  //     navigate("/home");
  //   }
  // };

  return (
    <div style={containerStyle}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "96%",
          padding: "2%",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            fetchpriority="high"
            src={MagicPaintsSymbol}
            alt="MagicPaints Logo"
            sizes="(max-width: 500px) 100vw, 500px"
            style={{ cursor: "pointer", width: "60px", height: "60px" }}
            // onClick={handleLogoClick}
          />
          <div style={textContainerStyle}>
            <span
              style={{
                fontSize: "20px",
                fontWeight: "900",
                fontFamily: "Roboto",
                color: "rgb(0 0 0)",
              }}
            >
              MAGIC PAINTS
            </span>
          </div>
        </div>

        <img src={NoviroLogo} alt="Noviro Logo" width={"90px"} />
      </div>
    </div>
  );
};

export default Header;
